import {deleteDataById, getDataBydId, getPaginatedData, insertData, patchData} from "./commonService";

export default class ResourceService {

    #defaultUrl = "/api/Resource";
    #defaultResourceName = "Metal";

    async getAllResources() {
        return await getPaginatedData(this.#defaultUrl, this.#defaultResourceName);
    }


    async insertResource(dataItem) {
        const createData = {
            "name": dataItem.name,
            "nameEng": dataItem.nameEng,
            "shortName": dataItem.shortName,
            "sortOrder": dataItem.sortOrder,
            "colorCode": dataItem.colorCode,
            "isActve": "true"
        }

        return await insertData(this.#defaultUrl, createData, this.#defaultResourceName);
    }

    async getResourceById(id) {
        return await getDataBydId(this.#defaultUrl, id, this.#defaultResourceName);
    }

    async deleteById(id) {
        return await deleteDataById(this.#defaultUrl, id, this.#defaultResourceName);
    }

    async updateResource(id, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "name", "value": dataItem.name},
            {"op": "replace", "path": "nameEng", "value": dataItem.nameEng},
            {"op": "replace", "path": "shortName", "value": dataItem.shortName},
            {"op": "replace", "path": "sortOrder", "value": dataItem.sortOrder},
            {"op": "replace", "path": "isActive", "value": dataItem.isActive},
            {"op": "replace", "path": "colorCode", "value": dataItem.colorCode},

        ];


        return await this.patchResource(id, patchDocument);
    }
    async patchResource(id, patchDocument) {
        return await patchData(this.#defaultUrl, id, patchDocument, this.#defaultResourceName);
    }
}