<template>
  <AdminToast/>
  <div class="grid">
    <div class="col-12">
      <Panel>
        <template #header>
          <b>Metal Bilgileri</b>
        </template>
        <div class="p-fluid grid">
          <div class="field col-12 md:col-12">
            <label for="name">Metal Adı</label>
            <InputText id="name" type="text" v-model="dataItem.name"/>
          </div>
          <div class="field col-12 md:col-12">
            <label for="nameEng">Metal Adı (İngilizce)</label>
            <InputText id="nameEng" type="text" v-model="dataItem.nameEng"/>
          </div>
          <div class="field col-12 md:col-12">
            <label for="colorCode">Renk Kodu</label>
            <InputText id="colorCode" type="text" v-model="dataItem.colorCode"/>
          </div>
          <div class="field col-12 md:col-12">
            <label for="shortName">Kısaltma</label>
            <InputText id="shortName" type="text" v-model="dataItem.shortName"/>
          </div>
          <div class="field col-12 md:col-12">
            <label for="sortOrder">Sıralama</label>
            <InputText id="sortOrder" type="text" v-model="dataItem.sortOrder"/>
          </div>
          <div class="field col-12 md:col-12">
            <label for="isActive">Durumu</label>
            <p>
              <InputSwitch id="isActive" v-model="dataItem.isActive"/>
            </p>
          </div>
          <div class="field col-12 md:col-12">
            <Button label="Kaydet" class="p-button-success p-button-raised p-button-rounded" @click="save"></Button>
          </div>
          <div class="field col-12 md:col-12">
            <Button label="Sil" class="p-button-danger p-button-raised p-button-rounded" @click="this.deleteConfirmationDialogVisibility= true" v-if="resourceId>0"></Button>
          </div>
        </div>
      </Panel>
      <Dialog
          v-model:visible="deleteConfirmationDialogVisibility"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
      >
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
          <span>Bu Kaynağı silmek istediğinize eminmisiniz?</span>
        </div>
        <template #footer>
          <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteConfirmationDialogVisibility = false"
          />
          <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedData"
          />
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import AdminToast from "@/components/shared/toast/adminToast";
import {showErrorMessage} from "@/components/shared/toast/toastFunction";
import {showSuccessMessage} from "../../components/shared/toast/toastFunction";
import ResourceService from "../../services/resourceService";

export default {
  _service: null,
  components: {AdminToast},
  created() {
    this._service = new ResourceService();
    this.resourceId = this.$route.params.resourceId;
  },
  async mounted() {
    await this.getResource(this.resourceId);
  },

  data() {
    return {
      returnField:"",
      deleteConfirmationDialogVisibility:false,
      managerUserId:0,
      dataItem: {
        name: "",
        isActive: "",
      }
    }
  },
  methods: {
    async save() {
      const updateResponse = await this._service.updateResource(this.resourceId, this.dataItem);
      if(updateResponse.isSuccess)
      {
        showSuccessMessage(this, "Kaynak başarı ile güncellendi");
      }
      else {
        showErrorMessage(this, "Kaynak güncellemede sorun ile karşılaşıldı");
      }
    },

    async deleteSelectedData() {
      this.deleteConfirmationDialogVisibility = false;
      const deleteResponse = await this._service.deleteById(this.resourceId);
      if (deleteResponse.isSuccess) {
        setTimeout( async () => await this.$router.push({name: "resourceList"}), 2000);
        showSuccessMessage(this, deleteResponse.message);


      } else {
        showErrorMessage(this, deleteResponse.message);
      }
    },
    async getResource(resourceId) {
      let getResponse = await this._service.getResourceById(resourceId);
      if(getResponse.isSuccess) {
        this.dataItem.name = getResponse.data.name;
        this.dataItem.nameEng = getResponse.data.nameEng;
        this.dataItem.shortName = getResponse.data.shortName;
        this.dataItem.sortOrder = getResponse.data.sortOrder;
        this.dataItem.isActive = getResponse.data.isActive;
        this.dataItem.colorCode = getResponse.data.colorCode;
      }
      else {
        showErrorMessage(this, getResponse.message);
      }
    }
  }
}
</script>