export function showSuccessMessage(_this, message) {
    _this.$toast.add({
        severity: 'success',
        summary: 'İşlem Başarılı',
        detail: message,
        life: 5000,
    });
}

export function showErrorMessage(_this, message) {
    _this.$toast.add({
        severity: 'error',
        summary: 'İşlem Başarısız',
        detail: message,
        life: 5000,
    });
}